import React from 'react';

function LoadingSpinner() {
  return (
    <div className="flex items-center justify-center h-screen bg-zinc-900">
      <div className="relative">
        <div className="w-12 h-12 rounded-full absolute border-4 border-zinc-800"></div>
        <div className="w-12 h-12 rounded-full animate-spin absolute border-4 border-blue-500 border-t-transparent"></div>
      </div>
      <span className="ml-6 text-zinc-400">Loading PRISM...</span>
    </div>
  );
}

export default LoadingSpinner; 